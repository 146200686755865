import { Dropdown, Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuSvg } from '../../../assets/icons/menu.svg';
import routes from '../../../pages/routes';
import './DropdownMenu.scss';
import productsRoutes from '../Products/productsRoutes';

const DropdownMenu = () => {
  const { t } = useTranslation();

  const items = [
    {
      key: 'products',
      label: t('nav.dropdown.products'),
      children: productsRoutes.map((pr) => ({
        key: pr.path,
        label: (
          <Link key={pr.path} to={[routes.productsPagePath(), pr.path].join('/')}>
            {t(pr.name)}
          </Link>
        ),
      })),
    },
    {
      key: 'services',
      label: <Link to={routes.servicesPagePath()}>{t('nav.dropdown.services')}</Link>,
    },
    {
      key: 'team',
      label: <Link to={routes.teamPagePath()}>{t('nav.dropdown.team')}</Link>,
    },
  ];

  return (
    <Dropdown overlay={<Menu items={items} />} trigger={['hover']} placement="top" arrow>
      <Button icon={<MenuSvg />} type="ghost" size="large" className="dropdown-btn" />
    </Dropdown>
  );
};

export default DropdownMenu;
