import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect } from 'react-router';
import { ToastContainer } from 'react-toastify';
import routesConfig from '../pages/routesConfig';
import Header from './core/Header/Header.jsx';
import Footer from './core/Footer/Footer.jsx';
import DropdownMenuMobile from './core/DropdownMenu/DropdownMenuMobile';
import Loader from './components/Loader/index.jsx';
import getMediaQueryMap from '../constants/mediaQueryMap.js';
import './App.scss';
import routes from '../pages/routes';
import AuthProvider from './components/Auth/AuthProvider';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Toastify.scss';

const RouterContainer = ({ children }) => {
  const { pathname } = useLocation();

  const classNames = cn({
    'h-100': true,
    'w-100': true,
    'router-container': true,
    'app-background': pathname === '/',
  });

  return <div className={classNames}>{children}</div>;
};

const App = () => {
  const mediaQueryMap = getMediaQueryMap();

  return (
    <Router>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <div className="app" id="App">
            {!window.matchMedia(mediaQueryMap.md).matches && (
              <DropdownMenuMobile pageWrapId="page-wrap" outerContainerId="App" />
            )}
            <div id="page-wrap" className="h-100">
              <RouterContainer>
                <Header />
                <Switch>
                  {routesConfig.map(({ path, isPrivate, Component }) =>
                    isPrivate ? null : (
                      <Route key={path} path={path} exact>
                        <Suspense fallback={<Loader />}>
                          <Component />
                        </Suspense>
                      </Route>
                    )
                  )}
                  <Route path="/*" exact>
                    <Redirect to={routes.mainPagePath()} />
                  </Route>
                </Switch>
                <Footer />
              </RouterContainer>
            </div>
            <ToastContainer />
          </div>
        </DndProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
