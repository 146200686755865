import { Layout } from 'antd';
import NavigationMenu from '../NavigationMenu/NavigationMenu.jsx';
import DropdownMenu from '../DropdownMenu/DropdownMenu.jsx';
import fadeInAnimation from '../../../animations/fadeIn.js';
import getMediaQueryMap from '../../../constants/mediaQueryMap';
import './Footer.scss';

const Footer = () => {
  const mediaQueryMap = getMediaQueryMap();
  const isDesktopView = window.matchMedia(mediaQueryMap.md).matches;

  return (
    <Layout.Footer
      className="page-footer z-0"
      style={{
        opacity: 0,
        animation: fadeInAnimation(),
        justifyContent: isDesktopView ? 'space-between' : 'flex-end',
      }}
    >
      {isDesktopView && <DropdownMenu />}
      <div
        className="z-100"
        style={{
          pointerEvents: 'fill',
        }}
      >
        <NavigationMenu />
      </div>
    </Layout.Footer>
  );
};

export default Footer;
