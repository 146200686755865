import axios from 'axios';
import { useHistory } from 'react-router';
import { logOut } from '../../core/ECard/services/authService';
import authTokenInterceptor from './utils/authTokenInterceptor';
import routes from '../../../pages/routes';

const AuthProvider = ({ children }) => {
  const history = useHistory();
  authTokenInterceptor();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && !error.config.url.includes('login')) {
        logOut();
        history.push(routes.loginPagePath());
      }
      throw error;
    }
  );

  return <>{children}</>;
};
export default AuthProvider;
