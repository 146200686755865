export default {
  translation: {
    logo: {
      title: 'Логотип компании',
    },
    nav: {
      contact: 'Контакты',
      dropdown: {
        products: 'Продукты',
        productsList: {
          whereIsMyChair: 'Где мой стул',
          patientDashboard: 'Кабинет пациента',
        },
        services: 'Услуги',
        team: 'Команда',
      },
    },
    languages: {
      ru: 'Русский',
      en: 'English',
      switchIcon: 'Сменить язык на {{ value }}',
    },
    main: {
      title_0: '<p>Привет</p>',
      title_1: '<p>Мы – <tertiary>DTechs</tertiary></p>',
      title_2: '<p>Мы разрабатываем, развиваем и сопровождаем</p>',
      title_3: '<p>цифровые сервисы и <secondary>продукты</secondary></p>',
    },
    forms: {
      errors: {
        required: 'Обязательное поле',
        min: 'Слишком коротко',
        email: 'Невалидный E-mail',
        whitespace: 'Присутсвуют пробелы',
      },
    },
    contact: {
      title: 'Время поговорить',
      meetTitle: 'Свяжись с нами',
      pitchTitle: 'Есть идея? Напиши нам',
      mail: 'office@digitaltechs.io',
      telegram: 'dtechsio',
      instagram: 'Instagram',
      phone: '+7 (995) 025-70-25',
      form: {
        name: 'Имя',
        email: 'E-mail',
        topic: 'Тема',
        send: 'Отправить',
        submittedMessage: 'Спасибо за обращение, {{senderName}}',
      },
    },
    products: {
      common: {
        downloadPdfText: 'Давай начнем новый проект вместе',
        downloadPdfButton: 'Узнать больше',
        earlyAccess: 'Ранний доступ',
        subscribeMessage: 'Подпишись на рассылку сейчас и окажись в числе первых!',
        details: 'Подробнее',
        return: 'Назад',
      },
      where_is_my_chair: {
        headline: 'Где мой стул?',
        subHeadlines: {
          0: 'Аренда рабочего места в салоне красоты',
          1: 'Для мастеров бьюти-индустрии',
          2: 'Переосмыслите то, как, когда и где вы работаете',
          3: 'Все необходимое для комфортной работы в одном месте',
          4: 'Аренда места в салоне. Вы принимаете клиентов – мы гарантируем стабильное функционирование IT-системы',
        },
        tags: {
          0: 'Онлайн-бронирование',
          1: 'Финансовые отчеты',
          2: 'Клиентская база данных',
          3: 'Обработка платежей',
          4: 'Напоминания о визите',
          5: 'Мобильное приложение',
        },
        subscribeForm: {
          title: 'Используй IT-технологии, чтобы увеличить доходы',
          description:
            'Наша бизнес-платформа поможет вам наладить гибкие рабочие часы, когда и где это вам необходимо.',
        },
      },
      patient_dashboard: {
        headline: 'Личный кабинет пациента',
        subHeadlines: {
          0: 'Заголовок 1',
          1: 'Заголовок 2',
          2: 'Заголовок 3',
          3: 'Заголовок 4',
          4: 'Заголовок 5',
        },
        tags: {
          0: 'Lorem ipsum',
          1: 'consectetur adipiscing',
          2: 'sed do eiusmod',
          3: 'labore et',
          4: 'quis nostrud',
          5: 'dolor sit',
        },
        subscribeForm: {
          title: 'Заголовок формы',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
      },
    },
    team: {
      FRONTEND: 'FRONTEND',
      BACKEND: 'BACKEND',
      PM: 'PM',
      QA: 'QA',
      soft: {
        teamwork: '',
        interviewing: '',
        reviewing: '',
        mentoring: '',
        workflow: '',
        tea: '',
      },
      QandA: {
        song: '',
        book: '',
        series: '',
        snack: '',
        hobby: '',
        before40: '',
        workEnjoy: '',
      },
    },
    eCard: {
      cardEdit: 'Изменить карту',
      cardCheck: 'Посмотреть карту',
      updateCard: 'Обновить карту',
      activateCard: 'Активировать карту',
      addPhoto: 'Сделать или загрузить фото',
      photoError: 'Произошла ошибка при загрузке',
      login: {
        logIn: 'Войти',
        signUp: 'Регистрация',
        login: 'Логин',
        password: 'Пароль',
        changeToSignUp: 'Зарегистрироваться',
        changeToLogIn: 'Войти',
        or: 'или',
      },
      lynx: {
        activeCard: 'activated',
        notActiveCard: 'not activated',
      },
      addContact: 'Добавить контакт',
      profile: {
        menu: {
          settings: 'Настройки',
          editProfile: 'Редактировать',
          lynx: 'Lynx',
          security: 'Безопасность',
        },
        userForm: {
          titles: {
            userForm: 'Как Вас зовут?',
            addPhoto: 'Улыбнитесь!',
            usernameData: 'Данные для входа',
            social: 'Соцсети и мессенджеры',
            contacts: 'Как с Вами связаться?',
            others: 'Прочее',
          },
          firstName: 'Имя',
          lastName: 'Фамилия',
          companyName: 'Организация',
          post: 'Должность',
          email: 'Эл. почта',
          phone: 'Телефон',
          site: 'Сайт',
          password: 'Пароль',
          username: 'Имя пользователя',
          accountLink: 'Привязать к существующему аккаунту',
          redirectLink: 'Ссылка на ваш сайт',
          needRedirect: 'Переадресация при сканировании',
          locale: 'Язык карты',
        },
        passwordForm: {
          oldPassword: 'Старый пароль',
          newPassword: 'Новый пароль',
          repeatPassword: 'Повторите пароль',
        },
      },
    },
    notifications: {
      loginError: 'Неверный логин или пароль',
    },
    general: {
      back: 'Назад',
      submit: 'Подтвердить',
      save: 'Сохранить',
    },
  },
};
