import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import productsRoutes from '../Products/productsRoutes';
import './DropdownMenuMobile.scss';
import routes from '../../../pages/routes';

const DropdownMenu = (props) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Menu {...props} isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="menu-item" href="#" onClick={closeSideBar}>
        {t('nav.dropdown.products')}
      </a>
      <div style={{ marginLeft: '15px' }}>
        {productsRoutes.map((pr) => (
          <Link
            className="menu-item"
            key={pr.path}
            to={[routes.productsPagePath(), pr.path].join('/')}
            style={{ color: '#fff' }}
            onClick={closeSideBar}
          >
            {t(pr.name)}
          </Link>
        ))}
      </div>
      <Link className="menu-item" to={routes.servicesPagePath()} onClick={closeSideBar}>
        {t('nav.dropdown.services')}
      </Link>
      <Link className="menu-item" to={routes.teamPagePath()} onClick={closeSideBar}>
        {t('nav.dropdown.team')}
      </Link>
    </Menu>
  );
};

export default DropdownMenu;
