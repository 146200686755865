import React from 'react';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { Helmet } from 'react-helmet';
import resources from './locales/index.js';
import App from './ui/App';
import { ThemeContainer } from './_modules';

const init = async () => {
  const i18n = i18next.createInstance();
  const userLanguage = navigator.language === 'ru' ? 'ru' : 'en';
  await i18n.use(initReactI18next).init({ resources, fallbackLng: userLanguage });

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="icon" type="image/x-icon" href="assets/icons/logo.png" />
      </Helmet>
      <ThemeContainer>
        <App />
      </ThemeContainer>
    </I18nextProvider>
  );
};

export default init;
